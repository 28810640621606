import React, { useState } from 'react';
import MultiAccordion from './Support/MultiAccordion';
import GeneralAccordion from './Support/GeneralAccordion';
import VpsAccordion from './Support/VpsAccordion';
import PermissionsAccordion from './Support/PermissionsAccordion';
import general from "../images/general.png";
import privacy from "../images/privacy.png";
import vps from "../images/vps-hoisting.png";
import { useRef } from 'react';

const Support = () => {
  const [activeSection, setActiveSection] = useState('general'); // State to track active section

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };


  const [faqTitle, setfaqTitles] = useState([
    {
      title: "Security Services", questions: [
        { id: 1, title: 'How can HostX enhance the security of my website', content: <p>HostX provides security services such as malware scanning and firewalls. Visit the "Security Services" section to explore our offerings and boost your website's protection.</p>, isOpen: false },
      ]
    },
    {
      title: "Backup Solutions", questions: [
        {
          id: 1, title: "Does HostX offer backup solutions for my website", content: <p>Yes, we provide backup solutions to ensure the safety of your data. Explore the "Backup Solutions" section to choose a plan that suits your backup needs.
          </p>, isOpen: false
        },

      ]
    },
    {
      title: "DMCA Takedown", questions: [
        { id: 1, title: "What is DMCA?", content: <p>The Digital Millennium Copyright Act (DMCA) is a federal law that empowers copyright holders to protect their intellectual property.</p>, isOpen: false },
        { id: 2, title: "How does DMCA protect my content?", content: <p>DMCA allows copyright holders to request the removal of infringing content from online platforms, ensuring the protection of their creative works.</p>, isOpen: false },
        { id: 3, title: "How can I initiate a DMCA takedown with HostX?", content: <p>You can initiate a DMCA takedown by providing us with the necessary details and evidence of copyright infringement through our designated form.</p>, isOpen: false },
      ]
    },
    {
      title: "Trademark Infringement", questions: [
        { id: 1, title: "What is Trademark Infringement?", content: <p>Trademark infringement occurs when a trademark is used without authorization in a way that may cause confusion about the source of goods or services.</p>, isOpen: false },
        { id: 2, title: "How does HostX address Trademark Infringement?", content: <p>We take trademark infringement seriously. If you believe your trademark is being abused, please report it to us with supporting evidence.</p>, isOpen: false },
        { id: 1, title: "", content: <p></p>, isOpen: false },
      ]
    },
    {
      title: "Spam", questions: [
        { id: 1, title: "What is Spam?", content: <p>Spam refers to Unsolicited Bulk Email sent to recipients without direct consent.</p>, isOpen: false },
        { id: 2, title: "How can I report Spam on HostX?", content: <p>If you encounter spam hosted on our platform, please report it using our Spam Reporting form.</p>, isOpen: false },
        { id: 1, title: "", content: <p></p>, isOpen: false },
      ]
    },
    {
      title: "Phishing", questions: [
        { id: 1, title: "What is Phishing?", content: <p>Phishing is the fraudulent practice of sending deceptive emails to individuals, posing as reputable companies, with the aim of extracting personal information.</p>, isOpen: false },
        { id: 2, title: "How does HostX combat Phishing?", content: <p>We actively investigate and take action against phishing activities reported to us. Use our Phishing Report form to submit incidents.</p>, isOpen: false },
      ]
    },
    {
      title: "Malware", questions: [
        { id: 1, title: "What is Malware?", content: <p>Malware is software designed to harm or disable computers and computer systems.</p>, isOpen: false },
        { id: 2, title: "How can I report Malware on HostX?", content: <p>Report any suspected malware hosted on our platform using our Malware Reporting form.</p>, isOpen: false },
      ]
    },
    {
      title: "Botnet", questions: [
        { id: 1, title: "What is a Botnet?", content: <p>A botnet is a network of private computers infected with malicious software, controlled without the owners' knowledge.      </p>, isOpen: false },
        { id: 2, title: "How does HostX handle Botnet activities?", content: <p>We investigate and mitigate reported botnet activities on our platform. Report incidents using our Botnet Reporting form.      </p>, isOpen: false },
      ]
    },
    {
      title: "Intrusion/Exploit Attempts", questions: [
        { id: 1, title: "What are Intrusion/Exploit Attempts?", content: <p>These are malicious attempts to access, crack, or locate vulnerabilities on machines without authorization.</p>, isOpen: false },
        { id: 2, title: "How can I report Intrusion/Exploit Attempts on HostX?", content: <p>Use our Intrusion/Exploit Reporting form to submit evidence of such attempts.</p>, isOpen: false },
      ]
    },
    {
      title: "Child Abuse, Violent Threats, and Harassment", questions: [
        { id: 1, title: "How does HostX address issues related to Child Abuse, Violent Threats, and Harassment?", content: <p>We have zero tolerance for such content. Report any instances using the respective reporting forms for swift action.</p>, isOpen: false },
      ]
    },
    {
      title: "Webmail", questions: [
        { id: 1, title: "How do I access my webmail?", content: <p>You can access your webmail by logging in through our website. Simply navigate to the "Webmail" section, enter your credentials, and explore your emails from any device with internet access.</p>, isOpen: false },
      ]
    },
    {
      title: "WHOIS", questions: [
        { id: 1, title: "What is WHOIS, and how can I use it?", content: <p>WHOIS is a database that provides information about domain registrations. To use it, visit our WHOIS lookup tool on the website. Enter the domain name, and you'll get details about its registration and ownership.</p>, isOpen: false },
      ]
    },
    {
      title: "HostX Mobile App", questions: [
        { id: 1, title: "Is there a HostX mobile app available?", content: <p>Yes, we offer a HostX Mobile App for easy management of your services on the go. Download it from your app store, log in with your credentials, and take control of your domains, hosting, and more.</p>, isOpen: false },
      ]
    },
    {
      title: "ICANN Confirmation", questions: [
        { id: 1, title: "What is ICANN confirmation, and why is it important?", content: <p> ICANN (Internet Corporation for Assigned Names and Numbers) confirmation is a verification process for domain registrations. It ensures accurate contact information. You may receive emails for confirmation, and it's crucial to respond to them to keep your domain active.</p>, isOpen: false },
      ]
    },
    {
      title: "Corporate Domains", questions: [
        { id: 1, title: "Can HostX handle corporate domain registrations?", content: <p>Absolutely! We specialize in corporate domain services. Our platform offers easy registration, management, and security features tailored for corporate domain needs.</p>, isOpen: false },
      ]
    },
    {
      title: "Redeem Code", questions: [
        { id: 1, title: "How do I redeem a code on HostX?", content: <p> To redeem a code, log in to your account, navigate to the "Redeem Code" section, enter your code, and follow the prompts. The redeemed benefits will be applied to your account.</p>, isOpen: false },
      ]
    },
    {
      title: "My Products ", questions: [
        { id: 1, title: " Where can I view and manage my purchased products on HostX?", content: <p>You can view and manage your products by logging into your account and visiting the "My Products" section. Here, you'll find a detailed list of your purchased services with options for management and renewal.</p>, isOpen: false },
      ]
    },
    {
      title: "Renewals & Billing", questions: [
        { id: 1, title: "How can I renew my services, and where can I find billing information?", content: <p>To renew services, go to the "Renewals & Billing" section in your account. Here, you can view invoices, choose billing preferences, and renew services conveniently.</p>, isOpen: false },
      ]
    },
    {
      title: "Create Account", questions: [
        { id: 1, title: " How do I create an account on HostX?", content: <p>To create an account, click on "Create Account" on the homepage. Fill in the required information, choose a plan, and follow the steps to set up your HostX account.</p>, isOpen: false },
      ]
    },
    {
      title: "Domain", questions: [
        { id: 1, title: "Can I transfer my existing domain to HostX?", content: <p>Yes, you can transfer your domain to HostX. Visit the "Domain" section, initiate the transfer process, and follow the steps for a seamless transfer experience.</p>, isOpen: false },
      ]
    },
    {
      title: "Email Registration", questions: [
        { id: 1, title: " How can I register an email with my domain?", content: <p>To register an email, go to the "Email Registration" section. Choose a plan, enter the necessary details, and set up professional email addresses associated with your domain.</p>, isOpen: false },
      ]
    },
    {
      title: "Web Hosting", questions: [
        { id: 1, title: "What features are included in HostX web hosting plans?", content: <p>HostX web hosting plans come with features such as ample storage, high bandwidth, one-click installs, and 24/7 support. Check our web hosting section for detailed plan information.</p>, isOpen: false },
      ]
    },
    {
      title: "SSL Certificates", questions: [
        { id: 1, title: " Why do I need an SSL certificate, and how can I get one?", content: <p>An SSL certificate ensures secure data transfer. To get one, visit the "SSL Certificates" section, choose a suitable certificate, and follow the steps to enhance your website's security.</p>, isOpen: false },
      ]
    },
    {
      title: "Website & App Builder", questions: [
        { id: 1, title: " Can I build a website or app on HostX without technical expertise?", content: <p>Yes, HostX offers user-friendly website and app builders. You can create stunning websites or apps without technical expertise. Explore the builder section for intuitive tools.</p>, isOpen: false },
      ]
    },
    {
      title: "Managed Services", questions: [
        { id: 1, title: " What does HostX's managed services include?", content: <p>HostX's managed services cover various aspects like server management, security, and updates. Check the "Managed Services" section for a comprehensive list of services and benefits.</p>, isOpen: false },
      ]
    },
    {
      title: "Other", questions: [
        { id: 1, title: "What if my abuse report doesn't fit into any specific category listed?", content: <p>Please use our General Abuse Reporting form to provide evidence of abuse outside the specified categories.      </p>, isOpen: false },
        { id: 2, title: "How quickly does HostX respond to abuse reports?", content: <p> We prioritize the swift investigation of all abuse reports, aiming for prompt and effective resolution.</p>, isOpen: false },
      ]
    },

  ])


  const [activefaq, setActivefaq] = useState()

  const targetRef = useRef(null);


  const handleDivClick = (category) => {
    setActivefaq(category);

    // Scroll to target div
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='container-fluid'>
      <div className='row mt-5'>
        <div className='col-sm-11 mb-5  mx-auto'>
          <div className='row mx-auto'>
            <div className='col-sm-11 my-5 mx-auto'>
              <div>
                <h1 style={{ textAlign: 'center' }}>Report abuse and illegal content</h1>
                <p className='my-5'>If you want to report an Internet breach to HostX, select an option below to create your report. Please provide the necessary details so that our team can investigate your complaint.<br />
                  If you have submitted a report by e-mail and have been forwarded to this form, your details are<br />
                  incomplete. In order for us to process your request, you must complete the form below and provide all the necessary information.
                </p>
              </div>

              <MultiAccordion />

            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-10 mb-5 mx-auto'>
          <div className='row mx-auto'>
            <div className='col-sm-6'>

              <div className='card mb-3 p-3'>
                <div className='row' onClick={() => handleSectionClick('vps')}>
                  <div className='col-sm-2'>
                    <img src={vps} alt='general ' height="45px" width="45px" />
                  </div>
                  <div className='col-sm-10'>

                    <p><b>VPS Hosting Features</b><br /><span style={{ fontSize: "15px" }}>Dive deeper into HostX's servers features. </span></p>

                  </div>

                </div>
              </div>
              <div className='card mb-3 p-3'>
                <div className='row' onClick={() => handleSectionClick('privacy')}>
                  <div className='col-sm-2'>
                    <img src={privacy} alt='general ' height="45px" width="45px" />
                  </div>
                  <div className='col-sm-10'>

                    <p><b>Permissions & Privacy</b><br /><span style={{ fontSize: "15px" }}>New around here? Start with the basics.</span> </p>

                  </div>

                </div>
              </div>
            </div>


            <div className='col-sm-6'>
              {activeSection === 'general' && (
                <div className='card p-5'>
                  <div className='row'>
                    <div className='col-sm-1'>
                      <img src={general} alt='general ' height="45px" width="45px" />
                    </div>
                    <div className='col-sm-10'>
                      <p className='ps-3'><b>General inquiries</b><br /><span style={{ fontSize: "15px" }}>New around here? Start with the basics.</span></p>
                      <GeneralAccordion />
                    </div>
                    <div className='col-sm-1'></div>
                  </div>
                </div>
              )}

              {activeSection === 'vps' && (
                <div className='card p-5'>
                  <div className='row'>
                    <div className='col-sm-1'>
                      <img src={vps} alt='general ' height="45px" width="45px" />
                    </div>
                    <div className='col-sm-10'>
                      <p className='ps-3'><b>VPS Hosting Features</b><br /><span style={{ fontSize: "15px" }}>Dive deeper into HostX's servers features. </span></p>
                      <VpsAccordion />
                    </div>
                    <div className='col-sm-1'></div>
                  </div>
                </div>
              )}

              {activeSection === 'privacy' && (
                <div className='card p-5'>
                  <div className='row'>
                    <div className='col-sm-1'>
                      <img src={privacy} alt='general ' height="45px" width="45px" />
                    </div>
                    <div className='col-sm-10'>
                      <p className='ps-3'><b>Permissions & Privacy</b><br /><span style={{ fontSize: "15px" }}>New around here? Start with the basics.</span></p>
                      <PermissionsAccordion />
                    </div>
                    <div className='col-sm-1'></div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>


      <div className='container '>

        <div className='row p-4'>
          <h1 style={{ textAlign: 'center' }}>Frequently Asked Questions (FAQs)</h1>


          {
            faqTitle?.map((item) => {
              return (
                <>
                  <div className='col-md-4 col-sm-6 col-12'>

                    <div className='card  mb-3 p-3' >
                      <div className='row' onClick={() => { handleDivClick(item) }}>
                        <div className='col-sm-2'>
                          <img src={general} alt='general ' height="45px" width="45px" />
                        </div>
                        <div className='col-sm-10 d-flex align-items-center'>

                          <p className='mb-0'><b>{item.title}</b> </p>

                        </div>

                      </div>
                    </div>
                  </div>

                </>
              )
            })
          }

        </div>
      </div>




      <div className='row'>
        <div className='col-sm-10 mb-5 mx-auto'>
          <div className='row mx-auto'>



          </div>
          <div className='col-12' ref={targetRef}>
            {activefaq && (
              <div className='card p-5' >
                <div className='row'>
                  <div className='col-sm-1'>
                    <img src={general} alt='general ' height="45px" width="45px" />
                  </div>
                  <div className='col-sm-10'>
                    <p className='ps-3'><b>{activefaq.title}</b></p>
                    <GeneralAccordion questions={activefaq.questions} />
                  </div>
                  <div className='col-sm-1'></div>
                </div>
              </div>
            )}

            {activeSection === 'vps' && (
              <div className='card p-5'>
                <div className='row'>
                  <div className='col-sm-1'>
                    <img src={vps} alt='general ' height="45px" width="45px" />
                  </div>
                  <div className='col-sm-10'>
                    <p className='ps-3'><b>VPS Hosting Features</b><br /><span style={{ fontSize: "15px" }}>Dive deeper into HostX's servers features. </span></p>
                    <VpsAccordion />
                  </div>
                  <div className='col-sm-1'></div>
                </div>
              </div>
            )}

            {activeSection === 'privacy' && (
              <div className='card p-5'>
                <div className='row'>
                  <div className='col-sm-1'>
                    <img src={privacy} alt='general ' height="45px" width="45px" />
                  </div>
                  <div className='col-sm-10'>
                    <p className='ps-3'><b>Permissions & Privacy</b><br /><span style={{ fontSize: "15px" }}>New around here? Start with the basics.</span></p>
                    <PermissionsAccordion />
                  </div>
                  <div className='col-sm-1'></div>
                </div>
              </div>
            )}

          </div>
        </div>




      </div>
    </div>
  )
}

export default Support;
