import React,{useState} from 'react'
import { useEffect } from 'react';

const GeneralAccordion = ({questions}) => {
    
    const [accordionItems, setAccordionItems] = useState();

        useEffect(()=>{
          setAccordionItems(questions)
        },[])
    
      const handleToggle = (index) => {
        setAccordionItems((prevItems) =>
          prevItems.map((item, i) => ({
            ...item,
            isOpen: i === index ? !item.isOpen : false,
          }))
        );
      };
    
      return (
        <div className="accordion accordion-flush" id="customAccordion">
          {accordionItems?.map((item, index) => (
            <div key={item.id} className="accordion-item">
              <p className="accordion-header" id={`accordionHeader${item.id}`}>
                <button
                  className={`accordion-button ${item.isOpen ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => handleToggle(index)}
                  aria-expanded={item.isOpen}
                  aria-controls={`accordionContent${item.id}`}
                >
                   {item.title}
                </button>
              </p>
              <div
                id={`accordionContent${item.id}`}
                className={`accordion-collapse collapse ${item.isOpen ? 'show' : ''}`}
                aria-labelledby={`accordionHeader${item.id}`}
              >
                <div className="accordion-body">{item.content}</div>
              </div>
            </div>
          ))}
        </div>
      );
  };

export default GeneralAccordion
